function init() {
    'use strict';
    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {

        $('.home').on('click', function (e) {
            const input = $('.input-field');
            if (!input.hasClass('hidden')) {
                input.addClass('hidden');
            }
        });

        $('.search-icon').on('click', function (e) {
            e.stopPropagation();
            const input = $('.input-field');
            const value = input.attr('value');
            const newValue = input.val();
            // check for value and if it exists submit form
            if (newValue !== value && newValue !== "") {
                $('.search-form').submit();
            }
            if (input.hasClass('hidden')) {
                input.removeClass('hidden');
            } else {
                input.addClass('hidden');
            }
        });

        $('header .search-bar .input-field').on('click', function (e) {
            e.stopPropagation();
        });

        $('.mobile-search-icon').on('click', function () {
            const input = $('.mobile-input-field');
            const value = input.attr('value');
            const newValue = input.val();
            // check for value and if it exists submit form
            if (newValue !== value && newValue !== "") {
                $('.mobile-search-form').submit();
            }
        });
    });
})(jQuery);
